import request from '@/utils/requestV2'

// 查询资源的上刊率并分组
export function listResourceUsedGb (data) {
  return request({
    url: '/ooh-dw/v1/datav/order/listResourceUsedGb',
    method: 'get',
    params: data
  })
}
// 获取订单列表
export function listOrders (data) {
  return request({
    url: '/ooh-dw/v1/datav/order/listOrders',
    method: 'get',
    params: data
  })
}
// 统计订单数量
export function countOrderNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/order/countOrderNumber',
    method: 'get',
    params: data
  })
}
// 统计资源售卖并分组-按资源分组统计
export function countSaleDataGbResource (data) {
  return request({
    url: '/ooh-dw/v1/datav/order/countSaleDataGbResource',
    method: 'get',
    params: data
  })
}
