<template>
  <div>
    <h2 class="text-center p-t-10 p-b-10">销售个人数据</h2>
    <Row class="text-center">
      <i-col span="9">
        <p class="remark p-b-5">目标额</p>
        <b class="text-16">{{formatMoney(countTargetContractAmountValue)}}</b>
      </i-col>
      <i-col span="9">
        <p class="remark p-b-5">签约额</p>
        <b class="text-16">{{formatMoney(countKpiContractAmountValue)}}</b>
      </i-col>
      <i-col span="6">
        <p class="remark p-b-5">完成率</p>
        <b class="text-16">{{countTargetContractFinishRateValue}}%</b>
      </i-col>
    </Row>

    <p class="situation-kpi-title m-t-10 m-b-5">各维度排名详情</p>
    <Row class="table-title p-l-10 p-r-10" :gutter="8">
      <i-col span="6">类别</i-col>
      <i-col span="6" class="text-center">排名</i-col>
      <i-col span="6">个人数据</i-col>
      <i-col span="6">人均数据</i-col>
    </Row>
    <Row v-for="(item,index) in listPersonalAbilityRankingData" :key="index" :gutter="8" class="p-t-5 p-l-10 p-r-10">
      <i-col span="6">{{item.name}}</i-col>
      <i-col span="6" class="text-center">{{item.number}}</i-col>
      <i-col span="6">{{formatNumber(item.actualAmount)}}</i-col>
      <i-col span="6">{{formatNumber(item.targetAmount)}}</i-col>
    </Row>

    <p class="situation-kpi-title m-t-10 m-b-5">活跃度</p>
    <Row class="p-b-5">
      <i-col span="8" class="title">上次登录</i-col>
      <i-col span="16" class="text-right">{{lastLoginTime}}</i-col>
    </Row>
    <Row class="p-b-5">
      <i-col span="8" class="title">活跃次数</i-col>
      <i-col span="16" class="text-right">{{userLoginNumber}} 次</i-col>
    </Row>
    <Row class="p-b-5">
      <i-col span="8" class="title">订单</i-col>
      <i-col span="16" class="text-right">{{orderNumber}} 单</i-col>
    </Row>
    <Row>
      <i-col span="8" class="title">合同</i-col>
      <i-col span="16" class="text-right">{{contractNumber}} 单</i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney, toNumber } from '@/utils/wnumb_own'

import {
  countTargetContractAmount, countKpiContractAmount, countTargetContractFinishRate,
  listPersonalAbilityRankingForKPI
} from '@/api/dw/kpi'
import { countUserLoginNumber, getLastLoginTime } from '@/api/dw/login'
import { countOrderNumber } from '@/api/dw/order'
import { countContractNumber } from '@/api/dw/datav'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      countTargetContractAmountValue: null,
      countKpiContractAmountValue: null,
      countTargetContractFinishRateValue: null,
      listPersonalAbilityRankingData: [],

      userLoginNumber: '',
      orderNumber: 0,
      contractNumber: 0,
      lastLoginTime: ''
    }
  },
  created () {
    this.initPageData()
    this.loadUserInfo()
  },
  methods: {
    initPageData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.companyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        userId: this.gbId
      }

      countTargetContractAmount(queryModel).then(res => {
        this.countTargetContractAmountValue = res[0].value
      })
      countKpiContractAmount(queryModel).then(res => {
        this.countKpiContractAmountValue = res[0].value
      })
      countTargetContractFinishRate(queryModel).then(res => {
        this.countTargetContractFinishRateValue = res[0].value
      })
      listPersonalAbilityRankingForKPI(queryModel).then(res => {
        this.listPersonalAbilityRankingData = res
      })
    },
    loadUserInfo () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        companyId: this.companyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        userId: this.gbId
      }

      countUserLoginNumber(queryModel).then(res => {
        this.userLoginNumber = res[0].value
      })
      countOrderNumber(queryModel).then(res => {
        this.orderNumber = res[0].value
      })
      countContractNumber(queryModel).then(res => {
        this.contractNumber = res[0].value
      })
      getLastLoginTime({ userId: this.gbId }).then(res => {
        this.lastLoginTime = res[0].value === '' ? '-' : res[0].value
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
        this.loadUserInfo()
      }
    },
    companyId () {
      this.initPageData()
      this.loadUserInfo()
    },
    gbId () {
      this.initPageData()
      this.loadUserInfo()
    }
  }
}
</script>
